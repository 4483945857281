<template>
  <BaseChart :chart-option="option" height="658px"/>
</template>

<script>
import BaseChart from '../../../../components/echarts/BaseChart.vue';
import 'echarts-wordcloud';

export default {
  name: 'WordCloudChart',
  components: {
    BaseChart,
  },
  props: {
    chartData: { type: Array, default: () => [] },
  },
  computed: {
    option() {
      return {
        backgroundColor: '#F7F9FE',
        //设置标题，居中显示
        title: {
          // text: '词频脑图',
          // left:'center',
        },
        //数据能够点击
        tooltip: {},
        series: [
          {
            //词的类型
            type: 'wordCloud',
            // maskImage:this.maskImage,
            //设置字符大小范围
            sizeRange: [26, 55],
            rotationRange: [-45, 0, 45, 90],
            rotationStep: 45,
            gridSize: 15,
            // shape: "diamond",
            left: 'center',
            top: 'center',
            right: null,
            bottom: null,
            drawOutOfBound: true,
            textStyle: {
              //生成随机的字体颜色
              color: function () {
                return 'rgb(' + [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                ].join(',') + ')';
              },
            },
            emphasis: {
              textStyle: {
                shadowBlur: 10,
                shadowColor: '#333',
                color: 'red',
              },
            },
            data: (this.chartData || []).map((i) => {
              return {
                name: i.keyword,
                value: i.frequency,
                ...i,
              };
            }),
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">

</style>
