<template>
  <div class="custom-date-select-root" style="width: 280px; display: flex">
    <div class="root-title">
      选择时间
    </div>

    <a-date-picker class="custom-date-select"></a-date-picker>
  </div>
</template>

<script>
export default {
  name: 'CustomDateSelect',
};
</script>

<style scoped lang="scss">
.custom-date-select-root {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 4px;
  height: 32px;
  overflow: hidden;

  &:hover {
    border-color: #2F54EB;
  }

  .root-title {
    width: 100px;
    line-height: 22px;
    margin: 5px 0;
    border-right: solid 2px #eee;
    text-align: center;
  }
}

/deep/ .custom-date-select {
  border: 0;
  outline: 0;

  .ant-calendar-picker-input:focus,
  .ant-calendar-picker-input {
    height: 30px;
    border: 0;
    outline: 0;
    box-shadow: none !important;
  }
}
</style>
