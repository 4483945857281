<template>
  <div class="group-wrap">
    <div class="header-title title">阶段</div>

    <div v-for="item in list" :key="item.id" class="title" :style="{height: item.height || null}">
      {{ item.name || '--' }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'GroupList',
  data() {
    return {
      list: [
        { id: 1, name: '场景', height: '160px' },
        { id: 2, name: '推荐度' },
        { id: 3, name: '满意度' },
        { id: 4, name: '惊喜度' },
        { id: 5, name: '告警度' },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 134px;
  min-height: 40px;
  margin-right: 24px;
  border-radius: 4px;
  padding-left: 18px;

  font-size: 14px;
  font-weight: 500;
  color: #333333;

  &.header-title {
    background: #F3F6FE;
  }
}
</style>
