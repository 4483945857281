<template>
  <a-card class="app-card">
    <template #title>
      <span class="app-card-title">体验驾驶舱</span>
    </template>

    <div class="flex scroller-x">
      <a-card :title="null" class="small-card">
        <GaugeChart :chart-data="[{name: '推荐度（NPS值）', value: '20'}]"/>
      </a-card>
      <a-card :title="null" class="small-card">
        <GaugeChart :chart-data="[{name: '满意度（T2B）', value: '85'}]"/>
      </a-card>
      <a-card :title="null" class="small-card">
        <GaugeChart :chart-data="[{name: '惊喜度（T2B）', value: '60'}]"/>
      </a-card>
      <a-card :title="null" class="small-card">
        <GaugeChart :chart-data="[{name: '告警率', value: '85'}]"/>
      </a-card>
    </div>
  </a-card>
</template>

<script>
import GaugeChart from './GaugeChart.vue';

export default {
  name: 'SatisfactionIndex',
  components: {
    GaugeChart,
  },
};
</script>

<style scoped lang="scss">
.scroller-x {
  overflow-x: auto;
}

.small-card {
  flex: auto;
  width: 320px;
  min-width: 290px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

  &:not(:first-child) {
    margin-left: 19px;
  }

  /deep/ .ant-card-body {
    padding: 0;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }

  .value {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }
}
</style>
