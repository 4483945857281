<template>
  <BaseChart :chart-option="option" height="260px" style="width: 100%;"/>
</template>

<script>
import BaseChart from '../../../../components/echarts/BaseChart.vue';

export default {
  name: 'GaugeChart',
  components: {
    BaseChart,
  },
  props: {
    chartData: { type: Array, default: () => [] },
  },
  computed: {
    option() {
      return {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '65%'],
            radius: '100%',
            min: 0,
            max: 100,
            splitNumber: 4,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2'],
                ],
              },
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '36%',
              width: 16,
              offsetCenter: [0, '-40%'],
              itemStyle: {
                color: 'inherit',
              },
            },
            axisTick: {
              length: 6,
              lineStyle: {
                color: 'inherit',
                width: 1,
              },
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: 'inherit',
                width: 2,
              },
            },
            title: {
              offsetCenter: [0, '30%'],
              fontSize: 14,
            },
            detail: {
              fontSize: 24,
              offsetCenter: [0, 0],
              valueAnimation: true,
              formatter: function (value) {
                return value + '%';
              },
              color: 'inherit',
            },
            data: this.chartData || [],
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">

</style>
