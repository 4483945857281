var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"app-card",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"app-card-title"},[_vm._v("词云统计")])]},proxy:true},{key:"extra",fn:function(){return [_c('a-radio-group',{staticClass:"app-radio-group button-groups",attrs:{"button-style":"solid"},model:{value:(_vm.wordCloud),callback:function ($$v) {_vm.wordCloud=$$v},expression:"wordCloud"}},_vm._l((_vm.wordCloudList),function(item){return _c('a-radio-button',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]},proxy:true}])},[_vm._l((_vm.cateList),function(row,rowIndex){return _c('div',{key:row.id,staticClass:"cate-row"},[_c('span',{staticClass:"cate-row-title"},[_vm._v(_vm._s(row.title))]),_c('div',{staticClass:"cate-row-children"},_vm._l((row.children),function(col){return _c('div',{key:col.id,staticClass:"cate-col",class:{'active': _vm.cate.includes(col.id)},on:{"click":function($event){return _vm.selectCate(col, rowIndex)}}},[_vm._v(" "+_vm._s(col.title)+" ")])}),0)])}),_c('div',{staticClass:"content"},[(_vm.wordCloud === 1)?_c('div',[_c('a-table',{staticClass:"table",attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"scroll":{ y: '540px' },"pagination":{
                 showTotal: (total) => `总条数：${total}` && false,
                 pageSizeOptions: ['10', '20', '50', '100'],
                 total: _vm.total,
                 showSizeChanger: false,
                 pageSize: _vm.pageSize,
               },"row-key":"id"}})],1):_c('div',[_c('WordCloudChart',{attrs:{"chart-data":_vm.dataSource}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }