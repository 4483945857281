<template>
  <a-card class="app-card" style="margin-bottom: 0!important;">
    <template #title>
      <span class="app-card-title">XM旅程</span>
    </template>

    <div class="main">
      <GroupList/>
      <BoardList/>
    </div>

  </a-card>
</template>

<script>
import GroupList from './fragment/GroupList.vue';
import BoardList from './fragment/BoardList.vue';

export default {
  name: 'XmIndex',
  components: {
    GroupList,
    BoardList,
  },
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
}
</style>
