<template>
  <div style="overflow-x: auto; flex: auto;">

    <div class="stage-list">
      <div v-for="(item) in stageList" :key="item.id" class="stage"
           :style="{color: item.color, backgroundColor: item.bg}">
        {{ item.title }}
      </div>
    </div>

    <div v-for="(item) in list" :key="item.id" class="card-list">
      <div v-for="(card) in item.children" class="card">
        <div v-if="item.type === 1" class="white-card">
          <div class="white-card-inner" :style="{borderLeftColor: card.color}">
            <div v-for="(text, index) in card.children" :key="index" class="text">{{ text }}</div>
          </div>
        </div>

        <div v-else class="dotted-card">{{ card.value }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import { stageList } from '../../wordCloud/source';

export default {
  name: 'BoardList',
  data() {
    return {
      stageList: stageList.slice(1),
      list: [
        {
          id: 101, type: 1, children: [
            { id: 101001, color: '#233365', children: ['购物体验', '客服相关', '活动优惠'] },
            { id: 101002, color: '#68AF47', children: ['购物体验', '客服相关', '活动优惠', '商品相关'] },
            { id: 101003, color: '#EE6666', children: ['物流相关'] },
            { id: 101004, color: '#73C0DE', children: ['商品相关'] },
            { id: 101005, color: '#5470C6', children: ['客服相关', '售后相关'] },
            { id: 101006, color: '#08B39C', children: ['客服相关', '售后相关'] },
            { id: 101007, color: '#2F54EB', children: ['客服相关', '商品相关', '物流相关'] },
          ],
        },
        {
          id: 102, type: 2, children: [
            { id: 102001, value: '-20%' },
            { id: 102002, value: '10%' },
            { id: 102003, value: '20%' },
            { id: 102004, value: '10%' },
            { id: 102005, value: '10%' },
            { id: 102006, value: '20%' },
            { id: 102007, value: '-20%' }],
        },
        {
          id: 103, type: 2, children: [
            { id: 103001, value: '10%' },
            { id: 103002, value: '20%' },
            { id: 103003, value: '10%' },
            { id: 103004, value: '20%' },
            { id: 103005, value: '10%' },
            { id: 103006, value: '5%' },
            { id: 103007, value: '10%' }],
        },
        {
          id: 104, type: 2, children: [
            { id: 104001, value: '20%' },
            { id: 104002, value: '' },
            { id: 104003, value: '' },
            { id: 104004, value: '20%' },
            { id: 104005, value: '' },
            { id: 104006, value: '' },
            { id: 104007, value: '' },
          ],
        },
        {
          id: 105, type: 2, children: [
            { id: 105001, value: '20%' },
            { id: 105002, value: '20%' },
            { id: 105003, value: '20%' },
            { id: 105004, value: '10%' },
            { id: 105005, value: '20%' },
            { id: 105006, value: '20%' },
            { id: 105007, value: '0%' },
          ],
        },
      ],
    };
  },
};

</script>

<style lang="scss" scoped>
$width: 150px;
$maxWidth: 300px;
$minWidth: 150px;

.stage-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  .stage {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $width;
    max-width: $maxWidth;
    min-width: $minWidth;
    height: 40px;

    font-size: 14px;
    font-weight: 500;
  }
}

.card-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.card {
  width: $width;
  max-width: $maxWidth;
  min-width: $minWidth;
  flex: auto;

  .white-card {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 160px;
    padding-top: 12px;

    .white-card-inner {
      width: calc(100% - 30px);
      //width: 119px;
      padding: 12px 28px;
      border-left: 6px solid;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: #3D3D3D;
      background: #FFFFFF;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

      .text {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .dotted-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      width: calc(100% - 15px);
      height: 32px;
      border: 1px dashed #CCCCCC;
      pointer-events: none;
    }
  }
}
</style>
