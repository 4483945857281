<template>
  <a-card class="app-card">
    <template #title>
      <span class="app-card-title">词云统计</span>
    </template>
    <template #extra>
      <a-radio-group v-model="wordCloud" button-style="solid" class="app-radio-group button-groups">
        <a-radio-button v-for="(item) in wordCloudList"
                        :key="item.id"
                        :value="item.id">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </template>

    <div v-for="(row, rowIndex) in cateList" :key="row.id" class="cate-row">
      <span class="cate-row-title">{{ row.title }}</span>

      <div class="cate-row-children">
        <div v-for="(col) in row.children"
             :key="col.id"
             class="cate-col"
             :class="{'active': cate.includes(col.id)}"
             @click="selectCate(col, rowIndex)">
          {{ col.title }}
        </div>
      </div>
    </div>

    <div class="content">
      <div v-if="wordCloud === 1">
        <a-table :columns="columns"
                 :data-source="dataSource"
                 :scroll="{ y: '540px' }"
                 :pagination="{
                   showTotal: (total) => `总条数：${total}` && false,
                   pageSizeOptions: ['10', '20', '50', '100'],
                   total: total,
                   showSizeChanger: false,
                   pageSize: pageSize,
                 }"
                 row-key="id"
                 class="table"></a-table>

      </div>
      <div v-else>
        <WordCloudChart :chart-data="dataSource"/>
      </div>
    </div>
  </a-card>
</template>

<script>
import { dimensionList, stageList, source } from './source';

import WordCloudChart from './WordCloudChart.vue';

export default {
  name: 'WordCloudIndex',
  components: { WordCloudChart },
  props: {
    data: { type: Array, default: () => [{ name: '词云', value: 0 }] },
  },
  computed: {
    dataSource() {
      return source.filter((i) => {
        if (this.cate[0] !== 1001) {
          if (i.dimensionId !== this.cate[0]) {
            return false;
          }
        }
        if (this.cate[1] !== 2001) {
          if (i.stageId !== this.cate[1]) {
            return false;
          }
        }

        return true;
      });
    },
  },
  watch: {
    data(val) {
      if (!val) {
        return;
      }
      this.updateChart();
    },
  },
  data() {
    return {
      wordCloud: 1,
      wordCloudList: [
        { id: 1, name: '列表' },
        { id: 2, name: '词云' },
      ],

      cate: [1001, 2001],
      cateList: [
        {
          id: 1,
          title: '指标',
          children: dimensionList,
        },
        {
          id: 2,
          title: '阶段',
          children: stageList,
        },
      ],

      total: 0,
      pageSize: 10,
      columns: [
        {
          title: '排名',
          dataIndex: 'rank',
          key: 'rank',
        },
        {
          title: '关键词',
          dataIndex: 'keyword',
          key: 'keyword',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.keyword.length - b.keyword.length,
        },
        {
          title: '指标',
          dataIndex: 'dimension',
          key: 'dimension',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.dimensionId - b.dimensionId,
        },
        {
          title: '阶段',
          dataIndex: 'stage',
          key: 'stage',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.stageId - b.stageId,
        },
        {
          title: '频数',
          dataIndex: 'frequency',
          key: 'frequency',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.frequency - b.frequency,
        },
      ],
    };
  },
  methods: {
    selectCate(col, rowIndex) {
      this.cate.splice(rowIndex, 1, col.id);
    },
  },
};
</script>

<style scoped lang="scss">
.cate-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 12px;

  .cate-row-title {
    flex: none;
    width: 60px;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  .cate-row-children {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cate-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;

    &.active {
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #3853E2;
      background-color: #F3F6FE;
    }
  }
}

.content {
  width: 100%;
  height: 658px;
}

.table {
  /deep/ .ant-table-thead > tr > th .ant-table-header-column {
    width: 100%;

    .ant-table-column-sorters {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
