<template>
  <div>

    <!-- <a-card :title="null" class="app-card">
      <div class="flex">
        <div class="flex">
          <CustomTreeSelect style="margin-right: 16px;"/>
          <CustomTreeSelectMulti style="margin-right: 16px;"/>
          <CustomDateSelect/>
        </div>

        <div class="button-groups">
          <a-button type="primary">查询</a-button>
          <a-button>重置</a-button>
        </div>
      </div>
    </a-card> -->

    <Satisfaction style="margin-top: 20px;"/>
    <WordCloud/>
    <Xm/>

  </div>
</template>

<script>
import CustomTreeSelect from '../../components/CustomTreeSelect.vue';
import CustomTreeSelectMulti from '../../components/CustomTreeSelectMulti.vue';
import CustomDateSelect from '../../components/CustomDateSelect.vue';

import Satisfaction from './section/satisfaction/Index.vue';
import WordCloud from './section/wordCloud/Index.vue';
import Xm from './section/xm/Index.vue';

export default {
  components: {
    CustomTreeSelect,
    CustomTreeSelectMulti,
    CustomDateSelect,

    Satisfaction,
    WordCloud,
    Xm,
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss">
.flex, /deep/ .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.flex-start {
    justify-content: flex-start;
  }
}

.button-groups, /deep/ .button-groups {
  button:not(:first-child) {
    margin-left: 12px;
  }

  .ant-btn-primary, .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border-color: #2F54EB;
    background-color: #2F54EB;

    &:hover {
      border-color: #209bff;
      background-color: #209bff;
    }
  }
}

.app-card, /deep/ .app-card {
  margin: 0 0 16px;
  border-radius: 4px;

  .app-card-title {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #3D3D3D;
  }

  /deep/ .ant-card-body {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.app-radio-group {
  /deep/ .ant-radio-button-wrapper:hover {
    color: #209bff;
  }

  /deep/ .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #2F54EB;
    background-color: #2F54EB;

    &:hover {
      border-color: #209bff;
      background-color: #209bff;
    }
  }
}
</style>
