let rank = 0;

const dimensionList = [
  { id: 1001, title: '全部' },
  { id: 1002, title: '推荐度' },
  { id: 1003, title: '满意度' },
  { id: 1004, title: '惊喜度' },
  { id: 1005, title: '内容预警' },
];

const stageList = [
  { id: 2001, title: '全部' },
  {
    id: 2002,
    title: '售前咨询',
    color: '#233365',
    bg: 'rgba(35,51,101,0.11)',
  },
  {
    id: 2003,
    title: '下单成功',
    color: '#68AF47',
    bg: 'rgba(104,175,71,0.11)',
  },
  {
    id: 2004,
    title: '物流到货',
    color: '#EE6666',
    bg: 'rgba(238,102,102,0.11)',
  },
  {
    id: 2005,
    title: '产品体验',
    color: '#73C0DE',
    bg: 'rgba(115,192,222,0.11)',
  },
  {
    id: 2006,
    title: '售后咨询',
    color: '#5470C6',
    bg: 'rgba(84,112,198,0.11)',
  },
  {
    id: 2007,
    title: '问题解决',
    color: '#08B39C',
    bg: 'rgba(8,179,156,0.11)',
  },
  {
    id: 2008,
    title: '评价跟进',
    color: '#2F54EB',
    bg: 'rgba(47,84,235,0.11)',
  },
];

function generateData(keyword, dimension, stage, frequency) {
  return {
    id: new Date().getTime() + Math.round(Math.random() * 10e8),
    rank: rank += 1,
    keyword,
    dimension,
    dimensionId: dimensionList.find((i) => i.title === dimension)?.id,
    stage,
    stageId: stageList.find((i) => i.title === stage)?.id,
    frequency,
  };
}

const source = [
  generateData('违反平台规则', '内容预警', '售前咨询', 15),
  generateData('推卸责任', '内容预警', '售后咨询', 15),
  generateData('方案不完整', '内容预警', '售前咨询', 13),
  generateData('让客户自行查询商品', '内容预警', '售前咨询', 10),
  generateData('未维护品牌形象', '内容预警', '评价跟进', 9),
  generateData('方案不恰当', '内容预警', '售后咨询', 6),
  generateData('敷衍消费者', '内容预警', '评价跟进', 6),
  generateData('品牌信息解释模糊', '内容预警', '评价跟进', 2),
  generateData('同理心差', '内容预警', '评价跟进', 2),
  generateData('态度强硬', '内容预警', '评价跟进', 2),
  generateData('上新速度快', '惊喜度', '售前咨询', 10),
  generateData('折扣力度大', '惊喜度', '售前咨询', 7),
  generateData('衣服质量很好', '惊喜度', '产品体验', 7),
  generateData('推荐的尺码刚刚好', '惊喜度', '产品体验', 6),
  generateData('有赠品', '惊喜度', '产品体验', 5),
  generateData('衣服显瘦', '惊喜度', '产品体验', 3),
  generateData('不掉色', '惊喜度', '评价跟进', 3),
  generateData('客服很有耐心', '惊喜度', '产品体验', 3),
  generateData('衣服没有色差', '惊喜度', '评价跟进', 2),
  generateData('性价比高', '惊喜度', '售前咨询', 2),
  generateData('款式很好看', '满意度', '下单成功', 18),
  generateData('喜欢裙子风格', '满意度', '下单成功', 16),
  generateData('配色好看', '满意度', '下单成功', 16),
  generateData('客服很有耐心', '满意度', '售前咨询', 12),
  generateData('优惠券多', '满意度', '售前咨询', 10),
  generateData('性价比高', '满意度', '产品体验', 8),
  generateData('百搭', '满意度', '售前咨询', 8),
  generateData('性价比高', '满意度', '产品体验', 8),
  generateData('物流速度快', '满意度', '物流到货', 8),
  generateData('是正品', '满意度', '产品体验', 6),
  generateData('性价比高', '推荐度', '产品体验', 12),
  generateData('质量好', '推荐度', '下单成功', 12),
  generateData('正品保证', '推荐度', '下单成功', 9),
  generateData('退换货方便', '推荐度', '售后咨询', 8),
  generateData('客服态度好', '推荐度', '售前咨询', 6),
  generateData('有运费险', '推荐度', '售后咨询', 5),
  generateData('好看', '推荐度', '下单成功', 5),
  generateData('款式流行', '推荐度', '下单成功', 3),
  generateData('商品可7天保价', '推荐度', '售后咨询', 3),
  generateData('科技感强', '推荐度', '下单成功', 1),
];

export {
  dimensionList,
  stageList,
  source,
};
